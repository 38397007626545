/**
 * File library.js
 * 
 * Custom scripts.
 */

(function($) {

	// Registering GSAP plugin

	gsap.registerPlugin(ScrollTrigger);

	// Global Variables

	var hamburger = $('.hamburger'),
		headerHeight = $('#masthead').outerHeight(),
		menuButton = $('.menu-button');
	
	// All-click objects

	function allClick() {
		var clickbox = $('.allclick');
		clickbox.each(function() {
			var $this = $(this);
			$this.css('cursor', 'pointer');
			$this.find('a').on('click', function(e) {
				e.preventDefault();
			});
			
			if ($this.find('a').attr('target') == '_blank') {
				$this.on('click', function(e) {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.on('click', function(e) {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
	}

	// Smooth scrolling

	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").on('click', function(e) {
			if (this.hash !== "") {
				e.preventDefault();

				var hash = this.hash;
				$('html, body').animate({
					scrollTop: $(hash).offset().top
				}, 1000);
			}
		});
	}

	// Code to run when the document is ready

	$( document ).ready(function() {

		allClick();
		smoothScroll();

		// Adjust margin for page without header
		
		if ( $('body').hasClass('no-header') ) {
			$('#page').css( 'margin-top', headerHeight );
		}

		// Intro Animations

		$('#page').addClass( 'init' );

		// Tabs
		
		var item = $('.tabs-nav .tab-menu'),
			tab = $('.tabs-area .tab-content');
		
		item.on( 'click', function() {
			$(this).addClass('selected').siblings().removeClass('selected');
			var index = $(this).index();
			tab.eq(index).addClass('selected').siblings().removeClass('selected');
		});

	});

	// Code to run when the entire page is ready

	$( window ).on( 'load', function() {

		/* GSAP Animations - Begin */

		// Header hide/show on scroll (Desktop)
		if ( $(window).width() > 1199 ) {
			var header = gsap.timeline({
				paused: true
			});

			header.from(
				'#masthead',
				{
					yPercent: -100,
					duration: 0.2
				}
			).progress(1);

			ScrollTrigger.create({
				start: 'top top',
				end: 99999,
				onUpdate: (self) => {
					self.direction === -1 ? header.play() : header.reverse()
				}
			});
		}

		// Menu (Mobile)
		var menu = gsap.timeline({
			paused: true
		});

		menu.to(
			'.menu-mobile',
			{
				duration: 0.75,
				x: 0,
				opacity: 1,
				ease: 'Power2.inOut',
				stagger: 0.1
			}
		).to(
			'.menu-mobile .menu-item',
			{
				duration: 0.5,
				opacity: 1,
				ease: 'Power2.inOut',
				stagger: 0.1
			},
			'-=0.1'
		);

		// Intro (Home)
		var intro = $('body.home #intro'),
			lines = gsap.utils.toArray('body.home #intro .lines'),
			venue = intro.find('.venue'),
			bkg = intro.find('.bkg'),
			megaphone = intro.find('.megaphone');

		if ( intro.length > 0 ) {
			gsap.from(
				lines,
				{
					scrollTrigger: {
						trigger: lines,
						start: 'top bottom',
						toggleClass: 'active',
						once: true
					}
				}
			);

			gsap.to(
				bkg,
				{
					delay: 1.25,
					duration: 1,
					scaleX: 0,
					scrollTrigger: {
						trigger: venue,
						start: 'top bottom'
					}
				}
			);

			gsap.from(
				megaphone,
				{
					delay: 1.5,
					duration: 1,
					scale: 0.25,
					ease: 'Bounce.easeOut'
				}
			);
		}

		// Intro (Programma)
		var programTitle = $('body.page-template-programma #intro h1'),
			brushStroke = $('body.page-template-programma #intro .brushstroke'),
			ellipse = $('body.page-template-programma #intro .ellipse');

		if ( programTitle.length > 0 ) {
			gsap.from(
				programTitle,
				{
					delay: 1.25,
					duration: 1.5,
					yPercent: 120
				}
			);

			gsap.from(
				brushStroke,
				{
					delay: 2,
					duration: 1.5,
					opacity: 0
				}
			);

			gsap.from(
				ellipse,
				{
					delay: 2.25,
					duration: 1.5,
					opacity: 0
				}
			);
		}

		// Intro (Relatori)
		var speakerTitle = $('body.page-template-relatori #intro h1');

		if ( speakerTitle.length > 0 ) {
			gsap.from(
				speakerTitle,
				{
					delay: 1.25,
					duration: 1.5,
					yPercent: 120
				}
			);
		}

		// Intro (Sistemazione)
		var accommodationTitle = $('body.page-template-sistemazione #intro h1'),
			brushStroke = $('body.page-template-sistemazione #intro .brushstroke'),
			pointsEllipse = $('body.page-template-sistemazione #intro .points-ellipse'),
			starEllipse = $('body.page-template-sistemazione #intro .star-ellipse');

		if ( accommodationTitle.length > 0 ) {
			gsap.from(
				accommodationTitle,
				{
					delay: 1.25,
					duration: 1.5,
					yPercent: 120
				}
			);

			gsap.from(
				brushStroke,
				{
					delay: 2.5,
					duration: 1.5,
					opacity: 0
				}
			);

			gsap.from(
				pointsEllipse,
				{
					delay: 2.75,
					duration: 1.5,
					opacity: 0
				}
			);

			gsap.from(
				starEllipse,
				{
					delay: 3,
					duration: 1.5,
					opacity: 0
				}
			);
		}

		// Intro (Partner)
		var partnerTitle = $('body.page-template-partner #intro h1'),
			brushStroke = $('body.page-template-partner #intro .brushstroke'),
			pointsEllipse = $('body.page-template-partner #intro .points-ellipse'),
			starEllipse = $('body.page-template-partner #intro .star-ellipse');

		if ( partnerTitle.length > 0 ) {
			gsap.from(
				partnerTitle,
				{
					delay: 1.25,
					duration: 1.5,
					yPercent: 120
				}
			);

			gsap.from(
				brushStroke,
				{
					delay: 2.5,
					duration: 1.5,
					opacity: 0
				}
			);

			gsap.from(
				pointsEllipse,
				{
					delay: 2.75,
					duration: 1.5,
					opacity: 0
				}
			);

			gsap.from(
				starEllipse,
				{
					delay: 3,
					duration: 1.5,
					opacity: 0
				}
			);
		}

		// Titles (sliding up)
		var titles = gsap.utils.toArray('.slide');

		if ( titles.length > 0 ) {
			titles.forEach((title) => {
				var delay = title.getAttribute('data-delay'),
					scroll = title.getAttribute('data-scroll'),
					start = 'top center';

				if ( scroll == 'no' ) start = 'top bottom';

				title.parentNode.style.overflow = 'hidden';

				gsap.from(
					title,
					{
						delay: delay,
						duration: 1.5,
						yPercent: 120,
						scrollTrigger: {
							trigger: title,
							start: start
						}
					}
				);
			});
		}

		// Texts (fading up)
		var texts = gsap.utils.toArray('.fade');

		if ( texts.length > 0 ) {
			texts.forEach((text) => {
				var delay = text.getAttribute('data-delay'),
					scroll = text.getAttribute('data-scroll'),
					start = 'top center';

				if ( scroll == 'no' ) start = 'top bottom';

				gsap.from(
					text,
					{
						delay: delay,
						duration: 1,
						autoAlpha: 0,
						y: 50,
						scrollTrigger: {
							trigger: text,
							start: start
						}
					}
				);
			});
		}

		// Classic fading
		var objects = gsap.utils.toArray('.classic-fade');

		if ( objects.length > 0 ) {
			objects.forEach((object) => {
				var delay = object.getAttribute('data-delay'),
					scroll = object.getAttribute('data-scroll'),
					start = 'top center';

				if ( scroll == 'no' ) start = 'top bottom';

				gsap.from(
					object,
					{
						delay: delay,
						duration: 1,
						autoAlpha: 0,
						scrollTrigger: {
							trigger: object,
							start: start
						}
					}
				);
			});
		}

		// Images reveal
		var containers = gsap.utils.toArray('.reveal');

		if ( containers.length > 0 ) {
			containers.forEach((container) => {
				var image = container.querySelector('img'),
					tl = gsap.timeline({
						scrollTrigger: {
							trigger: container,
							start: 'top center'
						}
					});
				
				tl.set(
					container,
					{
						autoAlpha: 1
					}
				);

				if ( container.classList.contains('right-side') ) {
					tl.from(
						container,
						2,
						{
							xPercent: 100,
							ease: 'Power2.easeOut'
						}
					);

					tl.from(
						image,
						2,
						{
							xPercent: -100,
							scale: 1.3,
							delay: -2,
							ease: 'Power2.easeOut'
						}
					);
				} else {
					tl.from(
						container,
						2,
						{
							xPercent: -100,
							ease: 'Power2.easeOut'
						}
					);

					tl.from(
						image,
						2,
						{
							xPercent: 100,
							scale: 1.3,
							delay: -2,
							ease: 'Power2.easeOut'
						}
					);
				}
			});
		}

		// Bounce effect
		var items = gsap.utils.toArray('.bounce');

		if ( items.length > 0 ) {
			items.forEach((item) => {
				gsap.from(
					item,
					{
						scale: 0.25,
						duration: 1,
						ease: 'Bounce.easeOut',
						scrollTrigger: {
							trigger: item,
							start: 'top bottom'
						}
					}
				);
			});
		}

		/* GSAP Animations - End */

		// Hide/show mobile menu

		hamburger.on('click', function(e) {
			e.preventDefault();

			menuButton.toggleClass('start');

			if ( ! menuButton.hasClass('start') ) {
				menu.play();
			} else {
				menu.reverse();
			}
		});

	});

})(jQuery);

// Magic mouse

if ( document.documentElement.clientWidth > 1199 ) {
	var options = {
		"cursorOuter": "circle-basic",
		"hoverEffect": "circle-move",
		"hoverItemMove": false,
		"defaultCursor": false,
		"outerWidth": 20,
		"outerHeight": 20
	};

	magicMouse(options);
}

/* Swiper - Begin */

// Text sliding

const textSlider = new Swiper('#program .sliding-text', {
	loop: true,
	slidesPerView: 'auto',
	centeredSlides: true,
	allowTouchMove: false,
	speed: 10000,
	autoplay: {
		delay: 5,
		disableOnInteraction: false,
	},
});

// Editions gallery slideshow

const ediGallerySlider = new Swiper('#editions .gallery', {
	loop: true,
	slidesPerView: 1,
	speed: 1000,
	breakpoints: {
		768: {
			slidesPerView: 2,
			slidesPerGroup: 2,
			spaceBetween: 30,
			speed: 2000,
		},
		1024: {
			slidesPerView: 2,
			slidesPerGroup: 2,
			spaceBetween: 60,
			speed: 2000,
		},
	},
});

// Included gallery slideshow

const incGallerySlider = new Swiper('#gallery .gallery-slideshow', {
	loop: true,
	slidesPerView: 1,
	speed: 1000,
	breakpoints: {
		768: {
			slidesPerView: 3,
			slidesPerGroup: 1,
		}
	},
});

// Speakers slideshow

const speakerSlider = new Swiper('#speakers-list', {
	loop: true,
	speed: 1000,
	effect: 'fade',
	fadeEffect: {
		crossFade: true
	},
	allowTouchMove: false,
	hashNavigation: true,
	navigation: {
		prevEl: '#speakers-list .swiper-button-prev',
		nextEl: '#speakers-list .swiper-button-next',
	},
});

// Area partner slideshow

const areaSlider = new Swiper('.area-slideshow', {
	loop: true,
	slidesPerView: 1,
	speed: 1000,
});

/* Swiper - End */

// Lightbox effects

const lightbox = GLightbox({
	loop: true
});
